import React from "react"
import * as shStyles from "./blog-header.module.css"

const BlogHeader = ({ title, date }) => (
  <div>
    <div className={shStyles.headerContainer}>
      <div className={shStyles.headerAlignBox}>
        <div className={shStyles.sectionHeader}>
          <div className={shStyles.sectionHeaderText}>
            <h1>{title}</h1>
            {date}
          </div>

          <svg className={shStyles.svg}>
            {/* <clipPath
              id="section-header-clip"
              clipPathUnits="objectBoundingBox"
            >
              <path d="M0,0 V0.076 L0.078,0.091,0.084,0.127,0,0.108 V1 L0.1,0.874 L0.122,1 H0.962 L0.992,0.874,1,0.325,0.917,0.081"></path>
            </clipPath> */}
          </svg>
        </div>
      </div>
    </div>
  </div>
)

export default BlogHeader
