import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
// import HeaderAlt from "../components/header"
import Layout from "../components/layout"
import BlogHeader from "../components/blog-header"
import SEO from "../components/seo"

import * as blogStyle from "./blog-template.module.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, file } = data
  const { frontmatter, html } = markdownRemark

  let featuredImg = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : file.childImageSharp.gatsbyImageData

  return (
    <div>
      <SEO title={frontmatter.title} />
      <Layout>
        <div className={blogStyle.featuredImageContainer}>
          <GatsbyImage image={featuredImg} className={blogStyle.featuredImage} />
        </div>
        <BlogHeader title={frontmatter.title} date={frontmatter.date} />
        <div className={blogStyle.blogPostContainer}>
          <div className={blogStyle.blogPost}>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
}

export const pageQuery = graphql`query ($slug: String!) {
  file(relativePath: {eq: "Ngan_Mythren_Promo_art.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 1400, height: 600, quality: 100, layout: FIXED)
    }
  }
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      slug
      title
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 1400, height: 600, quality: 100, layout: FIXED)
        }
      }
    }
  }
}
`
